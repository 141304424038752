import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import SecureLS from 'secure-ls';
import { UserOwner, User } from 'types/User';
import AuthService from 'services/api/AuthService';
import { useUserOwner } from 'views/auth/hook/auth_hook';
import { useQueryClient } from 'react-query';

const ls = new SecureLS({ encodingType: 'aes' });

type AuthPayload = {
  token: string;
  user: User;
};

export interface AuthContextType {
  auth?: AuthPayload;
  owner?: UserOwner;
  ownerIsLoading?: boolean;
  login: (auth: AuthPayload) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<AuthContextType>({
  auth: undefined,
  owner: undefined,
  ownerIsLoading: false,
  login: () => {},
  logout: () => {},
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const getUser = () => {
  try {
    return ls.get('user') ? ls.get('user') : undefined;
  } catch (error) {
    return undefined;
  }
};

export const getToken = () => {
  try {
    return ls.get('token') ? ls.get('token') : undefined;
  } catch (error) {
    return undefined;
  }
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { data: owner, isLoading: ownerIsLoading } = useUserOwner();
  const queryClient = useQueryClient();

  const user = useMemo(getUser, []);
  const token = useMemo(getToken, []);

  const initialAuth = user && token ? { user, token } : undefined;

  const [auth, setAuth] = useState<AuthContextType['auth']>(initialAuth);

  const login: AuthContextType['login'] = (auth) => {
    setAuth(auth);
    ls.set('token', auth?.token);
    ls.set('user', auth?.user);
  };

  const logout = () => {
    setAuth(undefined);
    queryClient.clear();
    ls.remove('token');
    ls.remove('user');
  };

  const refreshCurrentUser = useCallback(() => {
    token &&
      AuthService.get_userInfo()
        .then(async (res: any) => {
          if (res.status === 200 || res.status === 201) {
            const user = await res.json();
            login({
              user,
              token,
            });
          }
        })
        .catch(() => {});
  }, [token]);

  useEffect(() => {
    refreshCurrentUser();
  }, [refreshCurrentUser]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        owner,
        ownerIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

import { LoadingScreen } from 'components';
import { AppLayout, AuthLayout, PrintLayout } from 'layouts';
import React, { Fragment, lazy, Suspense } from 'react';
import { RouteConfig } from 'react-router-config';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppGuard, AuthGuard } from 'routes/guards';
import RoleGuard from 'routes/guards/RoleGuard';
import { UserType } from 'types/User';

export const routesConfig: RouteConfig[] = [
  {
    path: '/errors/error-404',
    exact: true,
    component: lazy(() => import('views/errors/404.page')),
    layout: Fragment,
    guard: Fragment,
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('views/home/Home.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/reinitialiser-mot-de-passe',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/creation-compte',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/connexion',
    exact: true,
    component: lazy(() => import('views/auth/Login.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/impersonate',
    exact: true,
    component: lazy(() => import('views/auth/Impersonate.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/mot-de-passe-oublie',
    exact: true,
    component: lazy(() => import('views/auth/ResetPasswordInit.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/mon-compte',
    exact: true,
    component: lazy(() => import('views/mon-compte/monCompte.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: ['/calculer-prime', '/simulations', '/simulations/:simulationId'],
    exact: true,
    component: lazy(
      () => import('views/calculer-une-prime/CalculerUnePrime.page'),
    ),
    layout: AppLayout,
    guard: RoleGuard,
    guardProps: {
      allowedTypes: [UserType.ENTREPRISE_DE_TRAVAUX],
    },
  },
  {
    path: '/tableau-de-bord',
    exact: true,
    component: lazy(() => import('views/dashboard/Dashboard.page')),
    layout: AppLayout,
    guard: RoleGuard,
    guardProps: {
      allowedTypes: [UserType.PARTNER],
    },
  },
  {
    path: '/tableau-de-bord/print',
    exact: true,
    component: lazy(() => import('views/dashboard/DashboardPrint.page')),
    layout: PrintLayout,
    guard: AppGuard,
  },
  {
    path: '/soumettre-dossier',
    exact: true,
    component: lazy(() => import('views/dossiers/ajout/AjoutDossier.page')),
    layout: AppLayout,
    guard: RoleGuard,
    guardProps: {
      allowedTypes: [UserType.PARTNER],
    },
  },
  {
    path: '/dossiers',
    exact: true,
    component: lazy(() => import('views/dossiers/list/ListeDossier.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/dossiers/:dossierId',
    exact: true,
    component: lazy(() => import('views/dossier/Dossier.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/dossiers/:dossierId/:operationId',
    exact: true,
    component: lazy(() => import('views/dossier/Dossier.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/documents',
    exact: true,
    component: lazy(() => import('views/documents/Documents.page')),
    layout: AppLayout,
    guard: RoleGuard,
    guardProps: {
      allowedTypes: [UserType.ENTREPRISE_DE_TRAVAUX],
    },
  },
  {
    path: '/faq',
    exact: true,
    component: lazy(() => import('views/faq/FAQ.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/mentions-legales',
    exact: true,
    component: lazy(() => import('views/not-implemented/NotImplemented.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/conditions-generales',
    exact: true,
    component: lazy(() => import('views/not-implemented/NotImplemented.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/politique-de-confidentialite',
    exact: true,
    component: lazy(() => import('views/not-implemented/NotImplemented.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '*',
    component: () => <Redirect to="/errors/error-404" />,
    layout: Fragment,
    guard: Fragment,
  },
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const GuardProps = route.guardProps || {};
          const Layout = route.layout || Fragment;
          const Component: any = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard {...GuardProps}>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;

export enum UserRole {
  MANAGER = 'MANAGER',
  TECHNICIAN = 'TECHNICIAN',
}

export enum UserType {
  PARTNER = 'PARTNER',
  ENTREPRISE_DE_TRAVAUX = 'ETX',
}

export interface User {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  firstname: string;
  lastname: string;
  numero_contrat: string;
  raison_sociale_entreprise: string;
  resetPasswordTokenCreatedAt: string | null;
  createdAt: Date;
  updatedAt: Date;
  type: UserType;
  role: UserRole;
  impersonated?: boolean;
  createdBy?: User;
  updatedBy?: User;
}

export interface UserOwner {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg';
import { Button, Divider, Popover, Spin, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from 'contexts/AuthContext';

export const ContactPopover = () => {
  const { t } = useTranslation('contact');
  const [open, setOpen] = useState(false);
  const { owner: data, ownerIsLoading: isLoading } = useAuthContext();

  return (
    <Popover
      onOpenChange={(visible) => setOpen(visible)}
      open={open}
      rootClassName={'no-padding-popover white-popover'}
      content={
        <div className={''}>
          <div className={'flex justify-between items-center pl-6 pr-3 py-3'}>
            <Typography.Paragraph className={'!mb-0 font-bold'}>
              {t('title')}
            </Typography.Paragraph>
            <Button
              type={'text'}
              icon={<CloseOutlined />}
              onClick={() => setOpen(false)}
            />
          </div>
          <Divider className={'m-0'} />
          <div className={'py-4 px-6'}>
            {isLoading && <Spin className={'mx-auto'} />}
            {!isLoading && !data?.email && (
              <Typography.Paragraph className={'!mb-0'}>
                {t('no_contact')}
              </Typography.Paragraph>
            )}
            {data && data.email && (
              <Typography.Paragraph className={'!mb-0 font-bold leading-8'}>
                {data.firstname} {data.lastname} <br />
                {t('global:phone')}:{' '}
                <a href={`tel:${data.phone}`}>{data.phone}</a>
                <br />
                {t('global:email')}:{' '}
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </Typography.Paragraph>
            )}
          </div>
        </div>
      }
      placement={'bottom'}
    >
      <PhoneIcon />
    </Popover>
  );
};

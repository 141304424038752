import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuthContext, getToken } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/api/AuthService';
import { useQuery, useQueryClient } from 'react-query';
import { UserInfos } from 'types/UserInfos';
import BaseService from 'services/api/BaseService';
import { UserUrls } from 'services/api/urls';
import { message } from 'antd';
import { UserOwner } from 'types/User';

export const usePasswordLoginUser = () => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [error, setError] = useState();
  const history = useHistory();
  const { login } = useAuthContext();
  const { t } = useTranslation();

  const mutation = async (email: string, password: string) => {
    setLoading(true);
    return AuthService.password_login({
      email,
      password,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          queryClient.clear();
          login({
            user: data.user,
            token: data.jwt,
          });
          history.push('/');
        } else {
          setError(data.message || t('login:login_error'));
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };
  return {
    loading,
    error,
    mutation,
  };
};

export const useImpersonate = () => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const history = useHistory();
  const { login } = useAuthContext();
  const { t } = useTranslation();

  const mutation = async (token: string) => {
    setLoading(true);
    return AuthService.impersonate(token)
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          queryClient.clear();
          login({
            user: {
              ...data.user,
              impersonated: true,
            },
            token: data.jwt,
          });
          history.push('/');
        } else {
          history.push('/connexion');
          message.error(data.message || t('login:token_error'));
        }
      })
      .catch(() => {
        history.push('/connexion');
        message.error(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };
  return {
    loading,
    mutation,
  };
};

export const usePasswordMutation = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const init = async (email: string) => {
    setLoading(true);
    return AuthService.reset_password_init(email)
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
        } else {
          setError(
            data.message ?? data.error.message ?? t('reset_password:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };

  const reset = async (newPassword: string, email: string, token: string) => {
    setLoading(true);
    return AuthService.reset_password({
      newPassword,
      email,
      token,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
        } else {
          setError(
            data.message ?? data.error.message ?? t('reset_password:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };

  const create = async (newPassword: string, token: string, email: string) => {
    setLoading(true);
    return AuthService.create_password({
      newPassword,
      token,
      email,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
        } else {
          setError(
            data.message ?? data.error.message ?? t('first_login:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => setLoading(false));
  };

  return {
    loading,
    error,
    success,
    init,
    reset,
    create,
  };
};

export const useUserInfos = () => {
  const { t } = useTranslation();

  return useQuery<UserInfos, Error>(UserUrls.GET_ACCOUNT_INFO, async () => {
    let data: any;
    try {
      const response = await BaseService.getRequest(
        UserUrls.GET_ACCOUNT_INFO,
        true,
      );
      data = await response.json();
      if ([200, 201].includes(response.status)) {
        return data;
      }
    } catch (e: any) {
      console.warn('Get account info error', e);
      throw new Error(t('global:internet_connexion_error'));
    }
    throw new Error(data?.message || t('global:internet_connexion_error'));
  });
};

export const useUserOwner = () => {
  const { t } = useTranslation();

  return useQuery<UserOwner, Error>(
    UserUrls.GET_ACCOUNT_OWNER_INFO,
    async () => {
      let data: any;
      if (!getToken()) {
        return {};
      }
      try {
        const response = await BaseService.getRequest(
          UserUrls.GET_ACCOUNT_OWNER_INFO,
          true,
        );
        data = await response.json();
        if ([200, 201].includes(response.status)) {
          return data;
        }
      } catch (e: any) {
        console.warn('Get account info error', e);
        throw new Error(t('global:internet_connexion_error'));
      }
      throw new Error(data?.message || t('global:internet_connexion_error'));
    },
  );
};
